import request from '@/utils/request'

//获取订单取消设置
export function getOrderCancle () {
    return request({
        url: '/order-service/lrOrderConfiguration/allOrderValidTime',
        method: 'get'
    })
}

//提交订单取消设置
export function subOrderCancle (value, key) {
    return request({
        url: "/order-service/lrOrderConfiguration/saveOrderValidTime?key=" + key + "&value=" + value,
        method: 'get'
    })
}
<template>
  <div class="pd-24 bx-bd">
    <div v-for='item in list' :key='item.id'>
      <div class="pd-24">{{item.title}}</div>
      <div class="pad-container">
        订单自动取消：未付款订单，将在<el-input
          class="search-input mlr-15"
          v-model="item.value"
        ></el-input
        >（分钟）后取消
        <div class="flx-row ali-c js-fe mt-50">
          <el-button class="form-button" type="primary" @click="save(item)">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getOrderCancle, subOrderCancle} from '@/api/config_center/orders_setting'
export default {
  data() {
    return {
      value: "",
      list:[
        {
          id: "SC",
          title: '商超订单',
          value: '',
        },
        {
          id: "BH",
          title: '百货订单',
          value: '',
        },
        {
          id: "TG",
          title: '团购订单',
          value: '',
        },
        {
          id: "YXHD",
          title: '营销活动订单',
          value: '',
        }
      ]
    };
  },
  mounted() {
    this.getValue()
  },
  methods: {
    getValue() {
      getOrderCancle().then(res => {
        res.data.body.forEach((item) => {
          let index = this.list.findIndex(ele => ele.id == item.key)
          if(index > -1) this.$set(this.list[index], 'value', item.value)
        })
      })
    },
    save(item) {
      // console.log(item)
      if(item.value === ''){
         this.$message.warning(item.title + '请输入分钟')
      }else{
        if(/^\d+$/.test(item.value)){
          // console.log('---')
          subOrderCancle(item.value,item.id).then(res => {
            this.$message({
              message: "保存成功",
              type: "success"
            })
          })
        }else{
           this.$message.warning(item.title + '请输入正整数')
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
